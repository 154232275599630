<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="0" tab="待审批">
      <a-radio-group v-model:value="type" style="margin-bottom: 20px;">
        <a-radio-button value="1">初审</a-radio-button>
        <a-radio-button value="2">终审</a-radio-button>
      </a-radio-group>
      <table-page v-if="type == '1'" :config="configFirst" />
      <table-page v-if="type == '2'" :config="config" />
    </a-tab-pane>
    <a-tab-pane key="1" tab="已拒绝">
      <table-page :config="configRejected" />
    </a-tab-pane>
    <a-tab-pane key="-1" tab="已通过">
      <table-page :config="configPassed" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";
export default {
  name: 'SaasAdminRebateRulesReviewDetail',
  components: { TablePage },
  props: ['selectedRows'],
  data() {
    return {
      activeKey: '0',
      type: '1',
      configFirst: {
        checkbox: true,
        dataUrl: `/agency/institutionsAudit/list?status=0`,
        filter: {
          initUrl: '/agency/institutionsCustomer/setAttributablePerson/get',
          controls: [
            {
              key: 'companySubject',
              label: '公司主体',
              type: 'text'
            },
            {
              key: 'name',
              label: '机构名',
              type: 'text',
            },
            {
              key: 'activateId',
              label: '提交人',
              type: 'souSelect',
              config: {
                options: 'employeesList'
              }
            },
          ]
        },
        columns: [
          {
            key: 'typeOfContract',
            title: '合同类型',
            width: '130px',
            type: 'textCustom',
            config: {
              text: (val) => {
                return val == false ? '纸质合同' : '电子合同'
              }
            }
          },
          {
            key: 'companySubject',
            title: '公司主体',
            width: '130px',
          },
          {
            key: 'signingTime',
            title: '签约时间',
            width: '130px',
            type: 'datetime'
          },
          {
            key: 'name',
            title: '机构名',
            width: '130px',
          },
          {
            key: 'contact',
            title: '联系人',
            width: '130px',
          },
          {
            key: 'provinceCityDistrict',
            title: '省市区',
            width: '130px',
          },
          {
            key: 'crm',
            title: '是否开通CRM',
            type: 'textCustom',
            width: '130px',
            config: {
              text: (val) => {
                return val == false ? '否' : '是'
              }
            }
          },
          {
            key: 'activate',
            title: '提交人',
            width: '130px',
          },
          {
            key: 'createdDate',
            title: '提交时间',
            width: '150px',
            type: 'date'
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '100px'
          }
        ],
        /* 表格操作 */
        actions: [
          {
            key: 'edit',
            text: '初审通过',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/agency/institutionsAudit/prePass',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条机构信息，是否通过初审? `
            },
            config: {
              title: '初审通过',
              submitUrl: '/agency/institutionsAudit/prePass',
              submitText: '确认',
              errorModal: true,
              controls: [
              ]
            }
          },
          {
            key: 'edit',
            text: '拒绝',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/agency/institutionsAudit/reject',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条机构信息，是否全部拒绝?`
            },
            config: {
              title: '审批拒绝',
              submitUrl: '/agency/institutionsAudit/reject',
              submitText: '确认',
              controls: [
                {
                  key: 'reply',
                  label: '审批回复',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 50, message: '只能输入1-50个字符' }
                    ]
                  }
                },
              ]
            }
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'detail',
            text: '详情',
            type: 'blank',
            permission: '/agency/institutionsAudit/get',
            config: {
              title: '详情',
              component: () => import("./institutionDetail")
            }
          },
        ]
      },
      config: {
        checkbox: true,
        dataUrl: `/agency/institutionsAudit/list?status=2`,
        filter: {
          initUrl: '/agency/institutionsCustomer/setAttributablePerson/get',
          controls: [
            {
              key: 'companySubject',
              label: '公司主体',
              type: 'text'
            },
            {
              key: 'name',
              label: '机构名',
              type: 'text',
            },
            {
              key: 'activateId',
              label: '提交人',
              type: 'souSelect',
              config: {
                options: 'employeesList'
              }
            },
          ]
        },
        columns: [
          {
            key: 'typeOfContract',
            title: '合同类型',
            width: '130px',
            type: 'textCustom',
            config: {
              text: (val) => {
                return val == false ? '纸质合同' : '电子合同'
              }
            }
          },
          {
            key: 'companySubject',
            title: '公司主体',
            width: '130px',
          },
          {
            key: 'signingTime',
            title: '签约时间',
            width: '130px',
            type: 'datetime'
          },
          {
            key: 'name',
            title: '机构名',
            width: '130px',
          },
          {
            key: 'contact',
            title: '联系人',
            width: '130px',
          },
          {
            key: 'provinceCityDistrict',
            title: '省市区',
            width: '130px',
          },
          {
            key: 'crm',
            title: '是否开通CRM',
            type: 'textCustom',
            width: '130px',
            config: {
              text: (val) => {
                return val == false ? '否' : '是'
              }
            }
          },
          {
            key: 'activate',
            title: '提交人',
            width: '130px',
          },
          {
            key: 'createdDate',
            title: '提交时间',
            width: '150px',
            type: 'date'
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '100px'
          }
        ],
        /* 表格操作 */
        actions: [
          {
            key: 'edit',
            text: '终审通过',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/agency/institutionsAudit/pass',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条机构信息，是否全部通过? `
            },
            config: {
              title: '审批回复',
              submitUrl: '/agency/institutionsAudit/pass',
              submitText: '确认',
              errorModal: true,
              controls: [
                {
                  key: 'reply',
                  label: '审批回复',
                  type: 'textarea',
                  config: {
                    rules: [
                      { min: 1, max: 50, message: '请尽量精简内容，输入50字以内' }
                    ]
                  }
                }
              ]
            }
          },
          {
            key: 'edit',
            text: '拒绝',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/agency/institutionsAudit/reject',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条机构信息，是否全部拒绝?`
            },
            config: {
              title: '审批拒绝',
              submitUrl: '/agency/institutionsAudit/reject',
              submitText: '确认',
              controls: [
                {
                  key: 'reply',
                  label: '审批回复',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 50, message: '只能输入1-50个字符' }
                    ]
                  }
                },
              ]
            }
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'detail',
            text: '详情',
            type: 'blank',
            permission: '/agency/institutionsAudit/get',
            config: {
              title: '详情',
              component: () => import("./institutionDetail")
            }
          },
        ]
      },
      configRejected: {
        dataUrl: `/agency/institutionsAudit/list?status=-1`,
        filter: {
          initUrl: '/agency/institutionsCustomer/setAttributablePerson/get',
          controls: [
            {
              key: 'companySubject',
              label: '公司主体',
              type: 'text'
            },
            {
              key: 'name',
              label: '机构名',
              type: 'text',
            },
            {
              key: 'activateId',
              label: '提交人',
              type: 'souSelect',
              config: {
                options: 'employeesList'
              }
            },
          ]
        },
        columns: [
          {
            key: 'reply',
            title: '审批回复',
            width: '130px',
          },
          {
            key: 'auditor',
            title: '审批人',
            width: '130px',
          },
          {
            key: 'auditDate',
            title: '审批时间',
            width: '130px',
            type: 'datetime'
          },
          {
            key: 'typeOfContract',
            title: '合同类型',
            width: '130px',
            type: 'textCustom',
            config: {
              text: (val) => {
                return val == false ? '纸质合同' : '电子合同'
              }
            }
          },
          {
            key: 'companySubject',
            title: '公司主体',
            width: '130px',
          },
          {
            key: 'signingTime',
            title: '签约时间',
            width: '130px',
            type: 'datetime'
          },
          {
            key: 'name',
            title: '机构名',
            width: '130px',
          },
          {
            key: 'contact',
            title: '联系人',
            width: '130px',
          },
          {
            key: 'provinceCityDistrict',
            title: '省市区',
            width: '130px',
          },
          {
            key: 'crm',
            title: '是否开通CRM',
            type: 'textCustom',
            width: '130px',
            config: {
              text: (val) => {
                return val == false ? '否' : '是'
              }
            }
          },
          {
            key: 'activate',
            title: '提交人',
            width: '130px',
          },
          {
            key: 'createdDate',
            title: '提交时间',
            width: '150px',
            type: 'date'
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '100px'
          }
        ],
        /* 表格操作 */
        actions: [],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'detail',
            text: '详情',
            type: 'blank',
            permission: '/agency/institutionsAudit/get',
            config: {
              title: '详情',
              component: () => import("./institutionDetail")
            }
          },
        ]
      },
      configPassed: {
        dataUrl: `/agency/institutionsAudit/list?status=1`,
        filter: {
          initUrl: '/agency/institutionsCustomer/setAttributablePerson/get',
          controls: [
            {
              key: 'companySubject',
              label: '公司主体',
              type: 'text'
            },
            {
              key: 'name',
              label: '机构名',
              type: 'text',
            },
            {
              key: 'activateId',
              label: '提交人',
              type: 'souSelect',
              config: {
                options: 'employeesList'
              }
            },
          ]
        },
        columns: [
          {
            key: 'reply',
            title: '审批回复',
            width: '130px',
          },
          {
            key: 'auditor',
            title: '审批人',
            width: '130px',
          },
          {
            key: 'auditDate',
            title: '审批时间',
            width: '130px',
            type: 'datetime'
          },
          {
            key: 'typeOfContract',
            title: '合同类型',
            width: '130px',
            type: 'textCustom',
            config: {
              text: (val) => {
                return val == false ? '纸质合同' : '电子合同'
              }
            }
          },
          {
            key: 'companySubject',
            title: '公司主体',
            width: '130px',
          },
          {
            key: 'signingTime',
            title: '签约时间',
            width: '130px',
            type: 'datetime'
          },
          {
            key: 'name',
            title: '机构名',
            width: '130px',
          },
          {
            key: 'contact',
            title: '联系人',
            width: '130px',
          },
          {
            key: 'provinceCityDistrict',
            title: '省市区',
            width: '130px',
          },
          {
            key: 'crm',
            title: '是否开通CRM',
            type: 'textCustom',
            width: '130px',
            config: {
              text: (val) => {
                return val == false ? '否' : '是'
              }
            }
          },
          {
            key: 'activate',
            title: '提交人',
            width: '130px',
          },
          {
            key: 'createdDate',
            title: '提交时间',
            width: '150px',
            type: 'date'
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '100px'
          }
        ],
        /* 表格操作 */
        actions: [],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'detail',
            text: '详情',
            type: 'blank',
            permission: '/agency/institutionsAudit/getInstitutions',
            config: {
              title: '详情',
              component: () => import("./institutionPassedDetail")
            }
          },
        ]
      }
    };
  }

};
</script>

<style lang="less" scoped></style>